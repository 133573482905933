<template>
   <div class="titleCat py-3 px-4 relative rounded-md mb-1" :class="isAddedEntity ? '' : 'mb-1'">
      <h2 class="text-sm font-semibold uppercase">
         <slot></slot>
         <img v-if="isAddedEntity" class="w-7 addItem" :src="require('@/assets/icon-plus.png')" @click="toggleEdit">
      </h2>
   </div>
</template>

<script>
export default {
   name: "TitleCat",
   props: {
      isAddedEntity: {
         required: true
      }
   },
   methods: {
      toggleEdit() {
         this.$emit('update:editing', !this.editing);
      },
   }
};
</script>
<style lang="scss" scoped>
.titleCat{
   background: #e6e1f1;
}
.addItem {
   position: absolute;
   right: 10px;
   top: 50%;
   transform: translateY(-50%);
   cursor:pointer;
   border: 2px solid #fff;
   border-radius: 50px;
   padding: 2px;
}
</style>
