<template>
   <div id="civilite">
      <TitleCat :isAddedEntity="false">Civilité</TitleCat>
      <div class="">
         <!-- Civilité, nom, prenom -->
         <div v-if="nom" class="flex wrap-items items-center">

            <selectField :value="civilites[civiliteId]" :options="civilites" :indexID="civiliteId" id="civilite_id"
               classSize="w-1/5" placeholder="Civilité" :editing="editingFields.nom" @input="civiliteId = $event"
               @keyup-enter="saveChange('nom')" />

            <InputTextField :value="prenom" id="prenom" classSize="w-1/5" :editing="editingFields.nom"
               @input="prenom = $event.target.value" @keyup-enter="saveChange('nom')" />

            <InputTextField :value="nom" id="nom" classSize="w-1/5" :editing="editingFields.nom"
               @input="nom = $event.target.value" @keyup-enter="saveChange('nom')" />

            <ActionFields :editing="editingFields.nom" field="nom" @update:editing="editingFields.nom = $event"
               @save-change="saveChange" />
         </div>
      </div>
   </div>
</template>

<script>
import TitleCat from "@/components/profil/global/TitleCat.vue";
import InputTextField from "@/components/profil/global/InputTextField.vue";
import selectField from "@/components/profil/global/selectField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import UserService from "@/services/user.service";
export default {
   name: "Civilite",
   components: {
      TitleCat,
      InputTextField,
      selectField,
      ActionFields,
   },
   props: {
      civiliteInfo: { required: true },
   },
   data() {
      return {
         nom: null,
         prenom: null,
         fonctionId: null,
         societeId: null,
         id: this.$route.params.id,
         civiliteId: null,
         editingFields: {
            nom: false,
            fonctionId: false,
         },
         civilites: {
            0: '(non défini)',
            2: 'Madame',
            3: 'Mademoiselle',
            1: 'Monsieur',
         },
      };
   },
   watch: {
      civiliteInfo(civiliteInfo) {
         if (civiliteInfo) {
            this.nom = civiliteInfo.nom;
            this.prenom = civiliteInfo.prenom;
            this.societeId = civiliteInfo.societeId;
            this.fonctionId = civiliteInfo.fonctionId;
            this.civiliteId = civiliteInfo.civiliteId;
         }
      },
   },
   computed: {
   },
   mounted() {
   
   },
   methods: {
      saveChange(field) {
         this.editingFields[field] = false;
         this.updateIndividu(field)
      },
      updateIndividu(field) {
         const data = {}; 

         switch (field) {
            case 'nom':
               data.nom = this.nom;
               data.prenom = this.prenom;
               data.civiliteId = parseInt(this.civiliteId);
               break;
            case 'fonctionId':
               data.fonctionId = this.fonctionId;
               break;
            case 'societeId':
               data.societeId = this.societeId;
               break;
            default:
               break;
         }
         console.log('data', data)
         UserService.putIndividus(this.$route.params.id, data).then(
            (response) => {
               console.log('response', response);
            },
            (error) => {
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
