<template>
   <div id="affaires">
      <TitleCat :isAddedEntity="true" @update:editing="addItemEditing = $event">Affaire(s)</TitleCat>

      <!-- Ajout d'une nouvel affaire -->
      <div class="add">
         <div v-if="addItemEditing" class="flex wrap-items items-center p-2 border-b border-gray-300 bg-slate-50">

            <selectField :value="newDemande_id" :options="demandesList" indexID="" id="type_adresse_id"
               classSize="w-2/6" placeholder="Demande" :editing="true" @change="newDemande_id = $event.target.value"
               @keyup-enter="saveAdd()" />

            <selectField :value="newType_action_affaire_id" :options="actionType" indexID="" id="type_adresse_id"
               classSize="w-2/6" placeholder="Type d'Action" :editing="true"
               @change="newType_action_affaire_id = $event.target.value" @keyup-enter="saveAdd()" />

            <ActionAddItem field="affaire" :editing="addItemEditing" @update:editing="addItemEditing = $event"
               @is-error="isError = false" @save-add="saveAdd()">
            </ActionAddItem>

         </div>
      </div>


      <div class="" :key="keyReload">
         <div v-for="(affaireItem, index) in affaires" :key="index" class="flex wrap-items items-center">

            <selectField :value="demandesList[affaireItem.demande_id]" :options="demandesList"
               :indexID="affaireItem.demande_id" id="formation_id" classSize="w-3/6" placeholder="Demande"
               :editing="false" @input="affaireItem.demande_id = $event" @keyup-enter="saveChange(index)" />

            <selectField :value="actionType[affaireItem.type_action_affaire_id]" :options="actionType"
               :indexID="affaireItem.type_action_affaire_id" id="type_action_affaire_id" classSize="w-3/6"
               placeholder="Type d'Action" :editing="affaireItem.editing"
               @input="affaireItem.type_action_affaire_id = $event" @keyup-enter="saveChange(index)" />

            <ActionFields :editing="affaireItem.editing" :index="index" field="adresse" :deletable="true"
               @is-error="isError = false" @update:editing="affaireItem.editing = $event"
               @save-delete="saveDelete(index)" @save-change="saveChange(index)"></ActionFields>

         </div>
      </div>
      <ErrorNotice v-if="isError" />
   </div>
</template>

<script>
import { reactive } from "vue";
import TitleCat from "@/components/profil/global/TitleCat.vue"; 
import selectField from "@/components/profil/global/selectField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import ActionAddItem from "@/components/profil/global/ActionAddItem.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import UserService from "@/services/user.service";
import { mapState } from 'vuex';

export default {
   name: "Cursus",
   components: {
      TitleCat, 
      ActionAddItem,
      selectField,
      ActionFields,
      ErrorNotice
   },
   props: {
      affaireInfo: { required: true, type: Array },
   },
   data() {
      return {
         affaires: reactive([]), // tableau où on stock tous les cursus de maniere reactive (avec index)
         id: this.$route.params.id,
         actionType: [],
         demandesList: [],
         addItemEditing: false,
         keyReload: 0, // variable pour update le component et mettre à jour les données apres les differentes operation
         newDemande_id: null,
         newType_action_affaire_id: null,
         isError: false
      };
   },
   computed: {
      ...mapState('dataType', ['dataType']),
   },
   watch: {
      affaireInfo: {
         handler(affaireInfo) {
            if (affaireInfo) {
               this.affaires = affaireInfo.map((info) => {
                  return {
                     demande_id: info.demande_id,
                     type_action_affaire_id: info.type_action_affaire_id,
                     editing: false,
                  };
               });
               this.getDemande()
            }
         },
         deep: true,
      },
      dataType(newValue) {
         this.actionType = {};
         this.demandesList = newValue.demandes;
         newValue.typeActionAffaires.forEach(item => {
            this.actionType[item.type_action_affaire_id] = item.type_action_affaire;
         });
      },
   },
   methods: {

      saveChange(index) {
         this.updateAffaire(index);
      },
      saveDelete(index) {
         this.affaires[index].editing = false;
         this.deleteAffaire(index);
      },
      saveAdd() {
        
         this.postAffaire();
      },

      updateAffaire(index) {
         const datas = {
            type_action_affaire_id: parseInt(this.affaires[index].type_action_affaire_id),
            demande_id: this.affaires[index].demande_id,
         };

         UserService.updateItem(this.$route.params.id, datas, 'affaires').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => {
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      
      deleteAffaire(index) {
         UserService.deleteItem(this.$route.params.id, 'affaires', parseInt(this.affaires[index].demande_id)).then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => { this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); }
         );
      },
      
      postAffaire() {
         const datas = {
            type_action_affaire_id: parseInt(this.newType_action_affaire_id),
            demande_id: parseInt(this.newDemande_id),
         };
         UserService.postItem(this.$route.params.id, datas, 'affaires').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
               this.isError = false
               this.addItemEditing = false;
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      getDemande() {
         console.log('GET DEMANDE')
      }
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
