<template>
   <div class="flex items-center" v-if="civiliteInfo">
      <h1 class="text-2xl font-semibold">{{civiliteInfo.prenom}} {{civiliteInfo.nom}}<br />
         <span class="text-base">{{ posteInfo.poste}}</span>
      </h1>
      <Button class="" @click-button="showModalMailing">Mailing</Button>
   </div>
</template>

<script>
import Button from "@/components/profil/global/Button.vue";
export default {
   name: "TitleProfil",
   components: {
      Button
   },
   props: {
      civiliteInfo: {required: true},
      posteInfo: {required: true}
   },
   methods: {
      showModalMailing() {
         this.$emit('show-modal-mailing', true);
      },
   },
};
</script>
<style lang="scss" scoped>
</style>
