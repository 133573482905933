<template>
   <div class="label min-w-44  py-1 px-2 mr-1">
      <slot></slot>
   </div>
</template>

<script>
export default {
   name: 'Label',
};
</script>