<template>
   <div id="reseau">
      <TitleCat :isAddedEntity="true" @update:editing="addItemEditing = $event">Reseau</TitleCat>

      <!-- Ajout d'un nouveau reseaux -->
      <div class="add">
         <div v-if="addItemEditing" class="flex wrap-items items-center p-2 border-b border-gray-300 bg-slate-50">
            <InputTextField value="" id="lien" classSize="w-5/6" :editing="true" placeholder="Lien"
               @input="newLien = $event.target.value" @keyup-enter="saveAdd()" />

            <ActionAddItem field="reseaux" :editing="addItemEditing" @update:editing="addItemEditing = $event"
               @is-error="isError = false" @save-add="saveAdd()">
            </ActionAddItem>
         </div>
      </div>

      <div class="" :key="keyReload">
         <div v-for="(reseauItem, index) in reseaux" :key="index" class="flex wrap-items items-center">
            <reseauField :value="reseauItem.lien" id="lien" classSize="w-5/6" :editing="reseauItem.editing"
               placeholder="Lien" @input="reseauItem.lien = $event" @keyup-enter="saveChange(index)" />

            <ActionFields :editing=" reseauItem.editing" :index="index" field="adresse" :deletable="true"
               @is-error="isError = false" @update:editing="reseauItem.editing = $event"
               @save-delete="saveDelete(index)" @save-change="saveChange(index)">
            </ActionFields>
         </div>
      </div>
      <ErrorNotice v-if="isError" />
   </div>
</template>

<script>
import { reactive } from "vue";
import TitleCat from "@/components/profil/global/TitleCat.vue";
import InputTextField from "@/components/profil/global/InputTextField.vue";
import reseauField from "@/components/profil/global/reseauField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import ActionAddItem from "@/components/profil/global/ActionAddItem.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import UserService from "@/services/user.service";
export default {
   name: "Telephone",
   components: {
      TitleCat,
      InputTextField,
      reseauField,
      ActionAddItem,
      ActionFields,
      ErrorNotice
   },
   props: {
      reseauInfo: { required: true, type: Array },
   },
   data() {
      return {
         reseaux: reactive([]), // tableau où on stock tous les reseaux de maniere reactive (avec index)
         id: this.$route.params.id,
         oldReseauArray: null,
         addItemEditing: false,
         keyReload: 0, // variable pour update le component et mettre à jour les données apres les differentes operation
         newLien: null,
         isError: false
      };
   },
   watch: {
      reseauInfo: {
         handler(reseauInfo) {
            if (reseauInfo) {
               this.reseaux = [];
               this.reseaux = reseauInfo.map((info) => {
                  return {
                     lien: info.lien,
                     editing: false,
                  };
               });
            }
            if (this.oldReseauArray == null) {
               this.oldReseauArray = [];
               this.reseaux.forEach((reseauInfo, index) => {
                  this.oldReseauArray[index] = reseauInfo.lien;
               });
            }
         },
         deep: true,
      },
   },
   methods: {

      saveChange(index) {
         this.updateReseau(index);
      },
      saveDelete(index) {
         this.reseaux[index].editing = false;
         this.deleteReseau(index);
      },
      saveAdd() { 
         this.postReseau();
      },

      updateReseau(index) {
         const datas = {
            newLien: this.reseaux[index].lien,
            oldLien: this.oldReseauArray[index]
         };
         UserService.updateItem(this.$route.params.id, datas, 'reseaux').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.oldReseauArray = null
               this.keyReload++
               this.reseaux[index].editing = false;
               this.isError = false
            },
            (error) => {
               this.isError = true;
               this.content = (
               error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      deleteReseau(index) {
         const modifiedLien = this.reseaux[index].lien.replace(/(\/)/g, '[slash]');
         console.log('oldReseauArray', this.oldReseauArray)
         UserService.deleteItem(this.$route.params.id, 'reseaux', modifiedLien).then(
            (response) => {
               console.log('response', response);
               this.oldReseauArray = null
               this.$emit('reload', true);
               setTimeout(() => {
                  this.keyReload++
               }, 500);
            },
            (error) => { this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); }
         );
      },
      postReseau() {
         const datas = {
            lien: this.newLien,
         };
         UserService.postItem(this.$route.params.id, datas, 'reseaux').then(
            (response) => {
               console.log('response', response);
               this.oldReseauArray = null
               this.$emit('reload', true);
               setTimeout(() => {
                  this.keyReload++
               }, 500);
               this.addItemEditing = false;
               this.isError = false
            },
            (error) => {
               this.isError = true
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
