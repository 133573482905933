<template>
    <div class="wrap wrap-pre rounded-md mb-1 mr-1" :class="editing ? classSize : ''">
        <span v-if="prefixe && editing" class="prefix">{{ prefixe }}</span>
        <p class="txt flex pl-2 py-1 border border-white" v-if="!editing">
            <span v-if="prefixe && !editing" class="prefix-simple">{{ prefixe }}</span>
            {{ value }}
        </p>
        <input type="email" :id="id" v-model="internalValue" required :class="prefixe ? 'pref-input' : ''"
            class="py-1 px-2 border rounded-md bg-gray-50 border-gray-300 w-full" v-else :placeholder="placeholder"
            @keyup.enter="$emit('keyup-enter')" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        classSize: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false
        },
        editing: {
            required: true
        },
        prefixe: {
            type: String,
        }
    },
    data() {
        return {
            internalValue: this.value // Initialisation de internalValue avec la prop value
        };
    },
    watch: {
        value(newValue) {
            // Synchronisation de la prop `value` avec internalValue si elle change de l'extérieur
            this.internalValue = newValue;
        }
    },
    methods: {
        // Émission de la valeur lorsque l'input change
        updateValue(newValue) {
            this.$emit('input', newValue); // Émission correcte de la nouvelle valeur
        }
    },
    // Utiliser l'option 'model' pour une synchronisation automatique
    model: {
        prop: 'value',
        event: 'input'
    }
};
</script>
<style lang="scss" scoped>
.wrap-pre {
    position: relative;
    display: inline-block;
    overflow: hidden;
    .prefix {
        position: absolute;
        left: 1px;
        top: 1px;
        height: calc(100% - 2px);
        pointer-events: none;
        width: 23px;
        background: #eee;
        border-right: 1px solid #d5d5d5;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .pref-input{
        padding-left: 30px!important;
    } 
}
</style>
