<template>
    <div class="wrap mr-1" :class="editing ? classSize : ''">
        <p class="txt mb-1 pl-2 py-1 border border-white" v-show="!editing">{{ formatedValue }}</p>
        <VueDatePicker v-model="newDateValue" :input-attr="{ placeholder: 'Enter date or use picker' }" text-input
            locale="fr" v-show="editing" cancelText="Annuler" selectText="Valider" format="dd/MM/yyyy" />
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: "DateField",
    components: {
        VueDatePicker
    },
    props: {
        value: {
            required: true
        },
        classSize: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        editing: {
            required: true
        }
    },
    data() {
        return {
            formatedValue: this.formatDate(this.value),
            newDateValue: this.value,
            updatedCalled: false
        };
    },
    updated() {
        if (!this.updatedCalled) {
            this.formatedValue = this.formatDate(this.value);
            this.newDateValue = this.value;
            this.updatedCalled = true;
        }
    },
    methods: {
        formatDate(dateString) {
            if (dateString) {
                const date = new Date(dateString);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                return date.toLocaleDateString('fr-FR', options);
            } else {
                return null;
            }
        },
    },
    watch: {
        newDateValue(newValue) {
            this.formatedValue = this.formatDate(newValue);
            this.$emit('input', newValue);
        }
    }
};
</script>

<style>
.vuejs3-datepicker__value {
    padding: 4px 15px;
    border-radius: 0;
    border-color: #ccc;
    min-width: 150px;
    width: 100%;
} 
.dp--tp-wrap{
    display: none
}
</style>
