<template>
   <div class="modal-mailing col-span-4 p-12 sm:p-14">
      <div class="close" @click="closeModal">Fermer</div>
      <div class="form">
         <div class="form-item">
            <label for="">Individu : {{ civiliteInfo.prenom }} {{ civiliteInfo.nom }} ( {{ defaultEmails[0]?.email }}
               )</label>
         </div>
         <div class="form-item">
            <label for="">Demande</label>
            <selectField :value="selectedDemande" :options="demandesList" indexID="" id="demande_id" classSize="w-2/6"
               placeholder="Demandes" :editing="true" @change="selectedDemande = $event.target.value" />
         </div>
         <Button class="mt-8" @click-button="saveDemande">Enregistrer</Button>
         <ErrorNotice v-if="isError" class="mt-5" />
         <SuccessNotice v-if="isSuccess" class="mt-5" />
      </div>
   </div>
</template>

<script>
import Button from "@/components/profil/global/Button.vue";
import UserService from "@/services/user.service";
import selectField from "@/components/profil/global/selectField.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import SuccessNotice from "@/components/profil/global/SuccessNotice.vue";
import { mapState } from 'vuex';

export default {
   name: "ModalMailing",
   components: {
      Button,
      selectField,
      SuccessNotice,
      ErrorNotice
   },
   props: {
      civiliteInfo: { required: true, type: Array },
      emailsInfo: { required: true, type: Array },  
   },
   data() {
      return {
         id: this.$route.params.id,
         defaultEmails: [],
         selectedDemande: null, 
         isError: false,
         isSuccess: false,
      };
   },
   computed: {
      ...mapState('dataType', ['dataType']),
      demandesList() {
         return this.dataType.demandes || [];
      }
   },
   mounted() {
      this.defaultEmails = this.emailsInfo.filter(email => email.isdefautemail === 1 || email.isdefautemail === -1);
   },
   // watch: {
   //    dataType(newValue) {
   //       console.log('FFFFFUUUUCK', newValue)
   //       this.demandesList = newValue.demandes;
   //    },
   // },
   methods: {
      saveDemande() {
         const datas = {
            type_mailing_id: parseInt(0),
            option_id: parseInt(1),
            option_value: parseInt(this.selectedOption),
         };

         UserService.postItem(this.$route.params.id, datas, 'mailing').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.isError = false
               this.isSuccess = true
            },
            (error) => {
               this.isSuccess = false
               this.isError = true
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      closeModal() {
         this.$emit('close-mailing', true);
      },
   },
};

</script>

<style lang="scss" scoped>
.modal-mailing {
   overflow-y: auto;
   height: 100%;
   position:fixed;
   top:30%;
   left: 50%;
   transform: translateX(-50%) translateY(-50%);
   width:50vw;
   height: 50vh;
   background: #fff;
   border: 1px solid #ddd;
   .close {
      position: absolute;
      cursor: pointer;
      top: 20px;
      right: 20px;
   }
   .item-contact{
      // border-top:1px solid #ffffff60;
      padding-top: 13px;
      margin-top: 13px;
      .date-infos{
         border-bottom:1px solid #ffffff2b;
         padding-bottom: 5px;
         margin-bottom: 5px;
         background: #ffffff15;
         padding: 5px;
      }
   }
}
</style>
