<template>
    <div class="wrap mr-1" :class="editing ? classSize : ''">

        <div v-if="textIsChecked" class="mb-1 pl-2 py-1 checkbox-text" v-show="!editing"
            :class="{ 'checked': internalValue === 1 }">
            <span v-if="internalValue === 1">{{ textIsChecked }}</span>
        </div>
        <div v-else class="checkbox-indicator" v-show="!editing" :class="{ 'checked': internalValue === 1 }">
            <div class="lbl"></div>
        </div>

        <div class="flex items-center" v-if="placeholder" v-show="editing">
             <div class="custom-checkbox">
                <input type="checkbox" :id="id" :checked="internalValue === 1" @change="updateInternalValue" required
                class="w-2/6 py-1 px-2 mb-1 w-5 h-5" v-show="editing" @keyup.enter="$emit('keyup-enter')">
            <label :for="id"></label>
            </div>
            <div v-show="editing" class="email-dft">{{ placeholder }}</div>
        </div>
        
        <div v-else class="custom-checkbox" v-show="editing">
            <input type="checkbox" :id="id" :checked="internalValue === 1" @change="updateInternalValue" required
                class="w-2/6 py-1 px-2 mb-1 w-5 h-5" v-show="editing" @keyup.enter="$emit('keyup-enter')">
            <label :for="id"></label>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        classSize: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        editing: {
            required: true
        },
        placeholder: {
        },
        textIsChecked: {
        }
    },
    data() {
        return {
            internalValue: null,
            updatedCalled: false,
        };
    },
    mounted() {
        this.defineInternalValue()
    },
    updated() {
        this.defineInternalValue()
    },
    watch: {
        value(newValue) {
            if (newValue === 1 || newValue === -1) {
                this.internalValue = 1;
            } else {
                this.internalValue = 0;
            }
        }
    },
    methods: {
        updateInternalValue(event) {
            this.internalValue = event.target.checked ? 1 : 0;
            this.$emit('input', this.internalValue);
        },
        defineInternalValue() {
            if (this.value === 1 || this.value === -1) {
                this.internalValue = 1;
            } else {
                this.internalValue = 0;
            }
        }
    }
};
</script>

<style lang="scss">
.email-dft{
    font-size: 14px;
    margin-left: 5px
}
.checkbox-indicator {
    position: relative;
    width: 20px;
    height: 20px;


    &.checked .lbl{
        border: 2px solid #000;
        &::after {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    .lbl {
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid #888;
        border-radius: 3px;
        background-color: #fff;
        position: absolute;
        margin-top: 0;

        &:after {
            content: "";
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 3px;
            background-color: black;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.2s ease;
        }

        &::before {
            content: '';
            background-image: url('~@/assets/icon-check.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 14px;
            filter: invert(1);
            position: absolute;
            height: 1.25rem;
            width: 1.25rem;
            z-index: 2;
            top: -1px;
            left: -1px;
        }
    }
}
</style>
