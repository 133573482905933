<template>
   <button class="inline-flex px-5 py-3 text-white bg-black rounded-md ml-auto"
      @click="clickButton">
      <slot></slot>
   </button>
</template>

<script>
export default {
   name: 'Button',
   methods: {
      clickButton() {
         this.$emit('click-button', true);
      },
   },
};
</script>