<template>
   <div id="civilite">
      <TitleCat :isAddedEntity="true" @update:editing="addItemEditing = $event">Cursus(s)</TitleCat>

      <div class="" :key="keyReload">
         <div v-for="(cursusItem, index) in cursuses" :key="index" class="flex wrap-items items-center">
            <selectField :value="formationTypes[cursusItem.formation_id]" :options="formationTypes"
               :indexID="cursusItem.formation_id" id="formation_id" classSize="w-3/6" placeholder="Type de Formation"
               :editing="false" @input="cursusItem.formation_id = $event" @keyup-enter="saveChange(index)" />

            <InputTextField :value="cursusItem.promotion" id="promotion" classSize="w-2/6" :editing="cursusItem.editing"
               placeholder="Promotion" @input="cursusItem.promotion = $event.target.value"
               @keyup-enter="saveChange(index)" />

            <ActionFields :editing="cursusItem.editing" :index="index" field="adresse" :deletable="true"
               @is-error="isError = false" @update:editing="cursusItem.editing = $event"
               @save-delete="saveDelete(index)" @save-change="saveChange(index)"></ActionFields>
         </div>
      </div>

      <!-- Ajout d'un nouveau cursus -->
      <div class="add">
         <div v-if="addItemEditing" class="flex wrap-items items-center p-2 border-b border-gray-300 bg-slate-50">

            <selectField :value="newFormation_id" :options="formationTypes" indexID="" id="type_adresse_id"
               classSize="w-2/6" placeholder="Type de Formation" :editing="true"
               @change="newFormation_id = $event.target.value" @keyup-enter="saveAdd('cursus')" />

            <InputTextField value="" id="promotion" classSize="w-2/6" :editing="true" placeholder="Promotion"
               @input="newPromotion = $event.target.value" @keyup-enter="saveAdd('cursus')" />

            <ActionAddItem field="cursus" :editing="addItemEditing" @update:editing="addItemEditing = $event"
               @is-error="isError = false" @save-add="saveAdd()">
            </ActionAddItem>
         </div>
      </div>
      <ErrorNotice v-if="isError" />
   </div>
</template>

<script>
import { reactive } from "vue";
import TitleCat from "@/components/profil/global/TitleCat.vue";
import InputTextField from "@/components/profil/global/InputTextField.vue";
import selectField from "@/components/profil/global/selectField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import ActionAddItem from "@/components/profil/global/ActionAddItem.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import UserService from "@/services/user.service";
export default {
   name: "Cursus",
   components: {
      TitleCat,
      InputTextField,
      ActionAddItem,
      selectField,
      ActionFields,
      ErrorNotice
   },
   props: {
      cursusInfo: { required: true, type: Array },
   },
   data() {
      return {
         cursuses: reactive([]), // tableau où on stock tous les cursus de maniere reactive (avec index)
         id: this.$route.params.id,
         formationTypes: {
            158: "",
            163: "3IL",
            249: "AGRO",
            18: "AUDENCIA",
            9: "BAC",
            8: "BAC + Formation/AP",
            194: "BAC+0",
            191: "BAC+1",
            167: "BAC+2",
            105: "BAC+3",
            106: "BAC+3 Informatique",
            96: "BAC+4",
            190: "BAC+5",
            192: "BAC+6",
            193: "BAC+7",
            200: "BAC+8",
            201: "BAC+9",
            11: "BTS",
            10: "BTS + Formation/AP",
            92: "BTS Electronique",
            12: "BTS Informatique",
            260: "CENTRALE Lille",
            122: "CENTRALE Lyon",
            256: "CENTRALE Marseille",
            159: "CENTRALE Nantes",
            137: "CENTRALE Paris",
            6: "CESI (4)",
            118: "CESTI",
            70: "CNAM (Ingénieur)",
            245: "DAEU",
            15: "DEA",
            220: "DEA Finances",
            16: "DEA Informatique",
            225: "DEA Maths",
            231: "DECF",
            19: "DESS",
            24: "DESS Finance",
            20: "DESS Informatique",
            130: "DESS Ingénierie Mathématique",
            5: "DEST (CNAM)",
            4: "DESTAUP",
            175: "DEUG",
            21: "DEUG A",
            22: "DEUG B",
            23: "DEUG Sciences éco",
            182: "DOCTORAT",
            178: "DOCTORAT Finance",
            25: "DOCTORAT Informatique",
            179: "DOCTORAT Mathématiques",
            187: "DPCT CNAM",
            87: "DU Concepteur en Informatique de Gestion",
            112: "DU Monétique et Courrier Electronique",
            107: "DU ",
            177: "DUT",
            28: "DUT Informatique",
            131: "DUT Réseaux et Télécom",
            29: "DUT Statistiques",
            89: "E3I",
            246: "E42",
            47: "ECAM",
            257: "ECAM",
            56: "ECE",
            13: "Ecole de commerce",
            30: "Ecole de Gestion (BAC+6)",
            90: "Ecole de gestion de l'INT",
            230: "EDHEC",
            103: "EERIE",
            34: "EFREI",
            196: "EI.CESI",
            247: "EIGSI",
            100: "EIGSI La Rochelle Ingénieur",
            35: "EISTI",
            140: "EIT",
            155: "EIVL",
            233: "EMBA",
            234: "EMBA DAUPHINE",
            227: "EMBA ESCP",
            226: "EMBA ESSEC",
            27: "EMBA HEC",
            214: "EMBA INSEAD",
            235: "EMBA MIT",
            239: "EMLV",
            195: "EN",
            36: "ENAC",
            37: "ENI Brest",
            199: "ENI Metz",
            213: "ENI Tarbes",
            128: "ENIC",
            198: "ENPC",
            202: "ENS",
            185: "ENSAE",
            240: "ENSAE PARISTECH",
            186: "ENSAI",
            253: "ENSAIT",
            123: "ENSAM",
            116: "ENSCL",
            170: "ENSCP",
            85: "ENSEA",
            104: "ENSEEIHT",
            38: "ENSEIRB",
            166: "ENSEM",
            217: "ENSGTI",
            91: "ENSI CAEN",
            258: "ENSI Mans",
            212: "ENSIAME",
            223: "ENSIBS",
            126: "ENSIC",
            153: "ENSICA",
            39: "ENSIEG",
            136: "ENSIETA",
            172: "ENSIGC",
            55: "ENSIIE",
            197: "ENSIIE",
            150: "ENSIL",
            134: "ENSIMAG",
            127: "ENSMM",
            102: "ENSPM",
            135: "ENSSAT",
            180: "ENSSAT",
            154: "ENSTA",
            143: "ENSTIB",
            41: "EPF",
            236: "EPFL",
            42: "EPITA",
            189: "EPITECH",
            162: "EPM",
            43: "EPSI",
            149: "ESAIGELEC",
            205: "ESC",
            241: "ESCEN",
            209: "ESCP",
            164: "ESEM",
            113: "ESEO",
            251: "ESG",
            110: "ESGI",
            46: "ESIAG",
            48: "ESIAL",
            181: "ESIAME",
            49: "ESIEA",
            50: "ESIEE",
            184: "ESIG",
            145: "ESIGEC",
            207: "ESIGED",
            51: "ESIGELEC",
            52: "ESIGETEL",
            161: "ESIL",
            133: "ESIL",
            156: "ESILV",
            148: "ESIM",
            53: "ESIP",
            232: "ESIPE",
            138: "ESISAR",
            14: "ESLSCA",
            54: "ESME-Sudria",
            244: "ESMSC",
            142: "ESO",
            139: "ESPEO",
            98: "ESSAIM",
            114: "ESSAIM",
            210: "ESSEC",
            121: "ESSI",
            125: "ESSTIN",
            132: "ESTACA",
            151: "ESTIA",
            222: "ESTIA",
            124: "ESTIT",
            101: "ESTP",
            95: "ETGL (Ingénieur)",
            115: "EUDIL",
            26: "EXECUTIVE MBA",
            3: "Formation A/P",
            93: "Formation inconnue",
            237: "GEM",
            2: "GRETA (Formation AP)",
            204: "HEC",
            215: "HEI",
            17: "HETIC",
            31: "IAE",
            206: "ICAM",
            250: "ICN",
            188: "IFIPS",
            219: "IFMA",
            146: "IFSIC",
            160: "IMAC",
            157: "IMERIR",
            40: "IMT Atlantique",
            69: "INGENIEUR ENSI",
            33: "INGENIEUR Informatique",
            254: "INGESUP",
            32: "Ingénieur",
            97: "Ingénieur d'affaires en Informatique",
            68: "Ingénieur EPSI",
            67: "Ingénieur ESIB",
            65: "INPG-ENSERG",
            86: "INPG-ENSHMG",
            64: "INSA",
            63: "INSA Lyon",
            216: "INSEEC",
            174: "INSIA",
            57: "INT",
            60: "IPSA",
            94: "IRESTE",
            208: "ISAT",
            211: "ISC",
            243: "ISEG",
            144: "ISEN",
            58: "ISEP",
            61: "ISERPA",
            203: "ISIA",
            1: "ISIMA",
            117: "ISITV",
            165: "ISIV",
            62: "ISMCM Ingénieur",
            129: "ISTASE",
            147: "ISTASE",
            99: "ISTY",
            252: "ITIN",
            108: "IUSPIM",
            7: "Jeunes Ingénieurs (SEMA)",
            255: "KEDGE",
            71: "LICENCE",
            72: "LICENCE Informatique",
            74: "MAITRISE",
            76: "MAITRISE AES",
            75: "MAITRISE Informatique",
            77: "MAITRISE Mathémathiques",
            78: "MAITRISE Science Eco",
            80: "MASTER",
            176: "MASTER Finance",
            81: "MASTER Informatique",
            183: "MASTER Mathématiques",
            218: "MASTER Spécialisé",
            221: "MBA",
            259: "MBA HEC",
            79: "MIAGE",
            84: "MIAGE (Niveau)",
            242: "MINES Albi",
            109: "MINES Alès",
            169: "MINES Douai",
            119: "MINES Nancy",
            66: "MINES Paris",
            120: "MINES Saint-Etienne",
            248: "NEOMA",
            224: "NOVANCIA",
            228: "POLYTECH",
            73: "POLYTECH Marseille",
            141: "POLYTECH Montpellier",
            229: "POLYTECH Nantes",
            238: "SKEMA",
            88: "Sup Galilée",
            152: "SUPAERO",
            44: "SUPELEC",
            45: "SUPINFO",
            59: "TELECOM Paris",
            82: "THESE",
            83: "THESE INFORMATIQUE",
            111: "UTBM",
            171: "UTC",
            173: "UTT",
            168: "X",
         },
         addItemEditing: false,
         keyReload: 0, // variable pour update le component et mettre à jour les données apres les differentes operation
         newFormation_id: null,
         newPromotion: null,
         isError: false
      };
   },
   watch: {
      cursusInfo: {
         handler(cursusInfo) {
            if (cursusInfo) {
               this.cursuses = cursusInfo.map((info) => {
                  return {
                     formation_id: info.formationId,
                     promotion: info.promotion,
                     editing: false,
                  };
               });
            }
         },
         deep: true,
      },
   },
   methods: {

      saveChange(index) {
         this.cursuses[index].editing = false;
         this.updateCursus(index);
      },
      saveDelete(index) {
         this.cursuses[index].editing = false;
         this.deleteCursus(index);
      },
      saveAdd() {
        
         this.postCursus();
      },

      updateCursus(index) {
         const datas = {
            formation_id: parseInt(this.cursuses[index].formation_id),
            promotion: this.cursuses[index].promotion,
         };

         UserService.updateItem(this.$route.params.id, datas, 'cursus').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => {
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      
      deleteCursus(index) {
         UserService.deleteItem(this.$route.params.id, 'cursus', parseInt(this.cursuses[index].formation_id)).then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => { this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); }
         );
      },

      postCursus() {
         const datas = {
            formation_id: parseInt(this.newFormation_id),
            promotion: this.newPromotion
         };
         UserService.postItem(this.$route.params.id, datas, 'cursus').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
               this.addItemEditing = false;
               this.isError = false
            },
            (error) => {
               this.isError = true
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
