<template>
    <div class="wrap mr-1 py-1" :class="editing ? classSize : ''">
        <a :href="value" target="blank" v-show="!editing">
            <img v-if="reseauType == 'viadeo'" class="w-6" :src="require('@/assets/viadeo.png')">
            <img v-else-if="reseauType == 'linkedin'" class="w-6" :src="require('@/assets/linkedin.png')">
            <img v-else-if="reseauType == 'malt'" class="w-6" :src="require('@/assets/malt.png')">
            <img v-else-if="reseauType == 'societe'" class="w-6" :src="require('@/assets/societe.png')">
            <img v-else class="w-6" :src="require('@/assets/website.png')">
        </a>
        <input type="email" :id="id" v-model="internalValue" required
            class="py-1 px-2 mb-1 border rounded-md bg-gray-50 border-gray-300 w-full" v-show="editing"
            :placeholder="placeholder" @keyup.enter="$emit('keyup-enter')">
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        classSize: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false
        },
        editing: {
            required: true
        }
    },
    data() {
        return {
            internalValue: this.value,
            updatedCalled: false,
            reseauType: this.checkUrl()
        };
    },
    updated() {
        if (!this.updatedCalled) {
            this.internalValue = this.value;
            this.updatedCalled = true;
        }
    },  
    watch: {
        internalValue(newValue) {
            this.$emit('input', newValue);
        }
    },
    methods: {  
        checkUrl() {
            const url = this.value;  
            var reseauType = null;
            if (url.indexOf('viadeo') !== -1) {
                reseauType = 'viadeo';
            } else if (url.indexOf('linkedin') !== -1) {
                reseauType = 'linkedin';
            } else if (url.indexOf('malt') !== -1) {
                reseauType = 'malt';
            } else if (url.indexOf('societe') !== -1) {
                reseauType = 'societe';
            } else {
                this.reseauType = null;
            }
            return reseauType
        }
    }
};
</script>
 