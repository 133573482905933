<template>
   <div class="modal-user">
      <div class="py-12 sm:py-14 px-6 sm:px-8 fullwidth space-y-6">
         <!-- Title profil  -->
         <TitleProfil :civiliteInfo="individu.civiliteInfo" :posteInfo="individu.profilInfo"
            @show-modal-mailing="showModalMailing = true" />

         <!-- 3 cols -->
         <div class="grid grid-cols-3 gap-7">
            <!-- <div class=""> -->
            <div class="col">
               <Civilite :civiliteInfo="individu.civiliteInfo" />
               <Telephone :telephoneInfo="individu.telephoneInfo" @reload="loadIndividu()" />
               <Email :emailsInfo="individu.emailsInfo" @reload="loadIndividu()" />
               <Adresse :adressesInfo="individu.adressesInfo" @reload="loadIndividu()" />
               <Cursus :cursusInfo="individu.cursusInfo" @reload="loadIndividu()" />
               <Reseau :reseauInfo="individu.reseauInfo" @reload="loadIndividu()" />
               <Competences :competencesInfo="individu.competencesInfo" @reload="loadIndividu()" />
            </div>
            <div class="col">
               <ProfilInfo :profilInfo="individu.profilInfo" @reload="loadIndividu()" />
               <Affaire :affaireInfo="individu.affaireInfo" @reload="loadIndividu()" />
            </div>
            <div class="col">
               <Contact :contactInfo="individu.contactInfo" @reload="loadIndividu()" />
            </div>
         </div>
         <!-- <UploadFile :prenom=" individu.prenom" :nom="individu.nom" /> -->
      </div>

      <ModalMailing v-if="showModalMailing" @close-mailing="showModalMailing = false"
         :civiliteInfo="individu.civiliteInfo" :emailsInfo="individu.emailsInfo" />
   </div>
</template>

<script>
import { ref  } from "vue";
import UserService from "@/services/user.service";
import Telephone from "@/components/profil/Telephone.vue"; 
import Adresse from "@/components/profil/Adresse.vue"; 
import Affaire from "@/components/profil/Affaire.vue"; 
import Competences from "@/components/profil/Competences.vue"; 
import Cursus from "@/components/profil/Cursus.vue"; 
import Reseau from "@/components/profil/Reseau.vue"; 
import Civilite from "@/components/profil/Civilite.vue"; 
import Contact from "@/components/profil/Contact.vue"; 
import ProfilInfo from "@/components/profil/ProfilInfo.vue"; 
import Email from "@/components/profil/Email.vue"; 
import TitleProfil from "@/components/profil/global/TitleProfil.vue"; 
import ModalMailing from "@/components/profil/ModalMailing.vue"; 
// import UploadFile from "@/components/profil/UploadFile.vue"; 
export default {
   name: "Profile",
   components: {
      Civilite,
      Cursus,
      Affaire,
      Reseau,
      Competences,
      Telephone,
      Adresse,
      Email,
      ProfilInfo,
      Contact,
      TitleProfil,
      ModalMailing,
      // UploadFile
   },
   data() {
      return {
         individu: ref([]),
         typesEmail: ref([]),
         loading: true,  
         showModalMailing: false,  
      };
   }, 
   computed: {
   },
   mounted() {
      setTimeout(() => {
         this.loadIndividu();
         // this.loadTypesEmail();
      }, 200);
   },
   methods: {
      loadIndividu() {
         const id = this.$route.params.id; 
         UserService.getIndividu(id).then(
            (response) => { 
               this.individu = response.data;
               console.log('response.data', response.data)
            },
            (error) => {
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      loadTypesEmail(){
         UserService.getTypeEmail().then(
            (response) => { 
               this.typesEmail = response.data["hydra:member"];
               console.log('response.data - typeEmail', response.data["hydra:member"])
            },
            (error) => {
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      // modifierConsultant() {
      //    UserService.putIndividus(
      //       this.individu.id, 
      //       this.individu.nom, 
      //       this.individu.prenom,
      //       this.individu.typeEmailId,
      //    ).then(
      //       (response) => { 
      //          console.log('response', response)
      //       },
      //       (error) => {
      //          this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      //       }
      //    );
      // },
     
   },
};
</script>
<style lang="scss" scoped>
.modal-user{
   margin-top: 0 !important;
  position: fixed;
  top: 80px;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fefefe;
  transform: translateX(100%);
  overflow-x: auto;
  padding-bottom: 80px;
   &.show {
      animation:slideinLeft .5s ease-in-out .6s forwards
   }
   &.animate-out {
      transform: translateX(0%);
      animation: slideOutLeft 0.5s ease-in-out 0s forwards;
   }
   @keyframes slideinLeft {
      from {
         transform: translateX(100%);
      }
      to {
         transform: translateX(0%);
      }
   }
   @keyframes slideOutLeft {
      from {
         transform: translateX(0%);
      }
      to {
         transform: translateX(100%);
      }
   }

}
</style>
