<template>
    <div class="actions cursor-pointer flex-row-reverse flex gap-2 items-center ml-auto min-w-16">
        <!-- <img v-if="!editing" class="w-6" :src="require('@/assets/icon-plus.png')" @click="toggleEdit"> -->
        <img v-if="editing" class="w-4" :src="require('@/assets/icon-close.png')" @click="toggleEdit">
        <img v-if="editing" class="w-5" :src="require('@/assets/icon-save.png')" @click="saveAdd">
    </div>
</template>

<script>
export default {
    props: {
        editing: {
            required: true
        },
        field: {
            required: true
        },
        deletable: {
            required: false
        }
    },
    methods: {
        toggleEdit() {
            this.$emit('update:editing', !this.editing);
            this.$emit('is-error', false);
        },
        saveAdd() {
            this.$emit('save-add', this.field);
        },
      
    }
}
</script>
