<template>
   <div id="civilite">
      <TitleCat :isAddedEntity="true" @update:editing="addItemEditing = $event">Téléphone(s)</TitleCat>
      <!-- Ajout d'un nouveau telephone -->
      <div class="add">
         <div v-if="addItemEditing" class="flex wrap-items items-center p-2 border-b border-gray-300 bg-slate-50">
            <InputTextField value="" id="nom" classSize="w-2/6" :editing="true" placeholder="Téléphone"
               @input="newTelephone = $event.target.value" @keyup-enter="saveAdd('telephone')" prefixe="0" />


            <selectField :value="newTypeTelephoneId" :options="typeTelephone" indexID="" id="type_email_id"
               classSize="w-2/6" placeholder="Type Téléphone" :editing="true"
               @change="newTypeTelephoneId = $event.target.value" @keyup-enter="saveAdd('telephone')" />

            <ActionAddItem field="telephone" :editing="addItemEditing" @update:editing="addItemEditing = $event"
               @is-error="isError = false" @save-add="saveAdd()">
            </ActionAddItem>
         </div>
      </div>

      <div class="" :key="keyReload">
         <!-- Telephone -->
         <div v-for="(telephoneItem, index) in telephones" :key="index" class="flex wrap-items items-center">
            <InputTextField :value="telephoneItem.telephone" id="nom" classSize="w-2/6" :editing="false"
               @input="telephoneItem.telephone = $event" @keyup-enter="saveChange(index)" prefixe="0" />

            <selectField :value="typeTelephone[telephoneItem.telephoneId]" :options="typeTelephone"
               :indexID="telephoneItem.telephoneId" id="telephone_id" classSize="w-2/6" :editing="telephoneItem.editing"
               @input="telephoneItem.telephoneId = $event" />

            <ActionFields :editing="telephoneItem.editing" :index="index" field="telephone" @is-error="isError = false"
               @update:editing="telephoneItem.editing = $event" @save-change="saveChange(index)"
               @save-delete="saveDelete(index)" :deletable="true"></ActionFields>
         </div>
      </div>
      <ErrorNotice v-if="isError" />

   </div>
</template>

<script>
import { ref, reactive } from "vue";
import TitleCat from "@/components/profil/global/TitleCat.vue";
// import Label from "@/components/profil/global/Label.vue";
import InputTextField from "@/components/profil/global/InputTextField.vue";
import selectField from "@/components/profil/global/selectField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import UserService from "@/services/user.service";
import ActionAddItem from "@/components/profil/global/ActionAddItem.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
export default {
   name: "Telephone",
   components: {
      TitleCat,
      // Label,
      ActionAddItem,
      InputTextField,
      selectField,
      ActionFields,
      ErrorNotice
   },
   props: {
      telephoneInfo: { required: true, type: Array },
   },
   data() {
      return {
         typesCivilite: ref([]),
         telephones: reactive([]),
         id: this.$route.params.id,
         typeTelephone: {
            1: 'Tel Domicile',
            2: 'Tel Mobile',
            3: 'Tel Bureau',
            4: 'Fax bureau',
            5: 'Tel Standard',
         },
         newTelephone: null,
         newTypeTelephoneId: null,
         addItemEditing: false,
         keyReload: 0, // variable pour update le component et mettre à jour les données apres les differentes operation
         isError: false
      };
   },
   watch: {
      telephoneInfo: {
         handler(telephoneInfo) {
            if (telephoneInfo) {
               this.telephones = telephoneInfo.map((info) => {
                  return {
                     telephone: info.telephone,
                     telephoneId: info.telephoneId,
                     editing: false,
                  };
               });
            }
         },
         deep: true,
      },
   },
   methods: {

      saveChange(index) {
         this.telephones[index].editing = false;
         this.updateTelephone(index);
      },
      saveDelete(index) {
         this.telephones[index].editing = false;
         this.deleteTelephone(index);
      },
      saveAdd() {
         this.postTelephone();
      },


      updateTelephone(index) { 
         const datas = {
            individuId: parseInt(this.$route.params.id),
            telephone: this.telephones[index].telephone.substr(0, 9),
            typeTelephoneId: parseInt(this.telephones[index].telephoneId),
         };

         UserService.updateItem(datas.telephone, datas, 'telephones').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => { this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); }
         );
      },

      deleteTelephone(index) {
         UserService.deleteItem(this.telephones[index].telephone.substr(0, 9), 'telephones').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => { this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); }
         );
      },

      postTelephone() {
         const datas = {
            individuId: parseInt(this.$route.params.id),
            telephone: this.newTelephone.substr(0, 9),
            typeTelephoneId: this.newTypeTelephoneId,
         }; 

         UserService.postItem(datas.telephone, datas, 'telephones').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
               this.addItemEditing = false;
               this.isError = false
            },
            (error) => {
               this.isError = true
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      }, 
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
