<template>
    <div class="error">
        <p class="text-white pl-2 py-1 bg-red-700">Erreur</p>
    </div>
</template>

<script>
export default {
    name: 'ErrorNotice',
};
</script>