<template>
    <div class="actions mb-1 cursor-pointer flex-row-reverse flex gap-2 items-center ml-auto min-w-20"
        :class="editing ? 'editing py-1 px-2' : ''">
        <img v-if="!editing" class="min-w-4 w-4" :src="require('@/assets/icon-pen.png')" @click="toggleEdit">
        <img v-else class="min-w-4 w-4" :src="require('@/assets/icon-close.png')" @click="toggleEdit">
        <img v-if="editing" class="min-w-4 w-4" :src="require('@/assets/icon-check.png')" @click="saveChange">
        <img v-if="editing && deletable" class="w-5" :src="require('@/assets/icon-trash.png')" @click="saveDelete">
    </div>
</template>

<script>
export default {
    props: {
        editing: {
            required: true
        },
        field: {
            required: true
        },
        deletable: {
            required: false
        }
    },
    methods: {
        toggleEdit() {
            this.$emit('update:editing', !this.editing);
            this.$emit('is-error', false);
        },
        saveChange() {
            this.$emit('save-change', this.field);
            // this.$emit('update:editing', false); 
        },
        saveDelete() {
            this.$emit('save-delete', this.field);
            this.$emit('update:editing', false); 
        }
    }
}
</script>
<style lang="scss" scoped>
    .editing{
        border: 1px solid #ddd;
        border-radius: 5px;
        min-height: 34px
    }
</style>